import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { ApiGet } from "../../helpers/apiCall";
import { Loader } from '../../assets/Loader/Loader';

function History() {
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState(null);
  const [loader, setLoader] = useState(false);
  const [paidOrders, setPaidOrders] = useState([]);
  useEffect(() => {
    setLoader(true);
    const query = new URLSearchParams(location.search);
    const email = query.get('pid');
    setEmail(email);
    if (email?.length > 0) {
      ApiGet(`payment/getPlayerPayment?email=${email}`).then((data) => {
        setPaidOrders(data.paidplayers);
        setLoader(false);
      });
    } else {
      navigate('/');
    }
  }, []);

    return ( <><main className="wrapper">
        <section>
        {
          loader ? <Loader/> : <div className="container">
          {
            paidOrders?.map(orderdetails =>{
                return  <div className="row mx-2 px-2  py-3 mt-3 border br-15 border-light" onClick={()=>navigate(`/league-billings-details?id=${btoa(orderdetails.stripe_payment_id)}`)} style={{cursor:'pointer'}}>
              <div className="col-11">
                <div className="row">
                  <div className="col-6 d-flex align-items-center justify-content-start">
                    <h6 className="mb-0 fw-600">{orderdetails.paid_by}</h6>
                  </div>
                  <div className="col-6 text-end">
                    <h3 className="text-primary">${orderdetails.payment_amount}</h3>
                  </div>
                </div>
                <div className="row f-12 mb-1 ">
                  <div className="col-6">
                    <p className="text-grey">Transaction ID</p>
                  </div>
                  <div className="col-6 text-end">
                    <button className="btn rounded-pill rounded px-3 py-0 f-10 btn-outline-success">
                      Paid
                    </button>
                  </div>
                </div>
                <div className="row f-12 ">
                  <div className="col-6">
                    <p>{orderdetails.stripe_payment_id}</p>
                  </div>
                  <div className="col-6 text-end">
                    <p className="text-grey">
                      <span>{moment(orderdetails.date_of_payment).format('MMMM Do YYYY')}</span>
                      <span className="mx-2">{moment(orderdetails.date_of_payment).format('h:mm:ss a')}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-1 d-flex justify-content-center align-items-center">
                <i className="fa-solid fa-chevron-right" />
              </div>
            </div>
            })
          }
          </div>
        }
          
        </section>
      </main>
      </> );
}

export default History;