import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { ApiGetAuth, Apipost } from "../../../helpers/apiCall";
import { GetBracket, roundRobin } from "../../../components/brackets/TournamentBracket";
import { errorMessageNodify, successMessageNodify } from "../../../helpers/notify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

function GenerateMatch(props) {
  const { groupId } = useParams();
  const [values, setValues] = useState({ game_bracket_id: 3 })
  const [generatedSuccess, setGeneratedSuccessfully] = useState(false);
  const [groupDetails, setGroupDetails] = useState([])
  function createMatchesForSport(id) {
    const { SelectedWeek, game_bracket_id, venue_id = null, venue_field_id = null } = values
    if (!(game_bracket_id)) return errorMessageNodify("Please Select the type of game ");
    props.setLoader(true)
    const data = values
    data.teams = groupDetails.teams
    if (game_bracket_id == 3) {
      const bracketData = roundRobin([data], data.robin_number);
      bracketData?.map(async (match) => {
        Apipost(
          `/games/create-match`,
          { group_id: match.group_id, matches: JSON.stringify(match.bracket), game_id: id, SelectedWeek: SelectedWeek, venue_id, venue_field_id },
          "application/x-www-form-urlencoded",
          true
        ).then(
          (response) => {
            if (response.status === true) {
              console.log("inside 3")
              props.setLoader(false)
              successMessageNodify(response.msg);
            } else {
              errorMessageNodify(response.msg);
              props.setLoader(false)
            }
          },
          (err) => {
            errorMessageNodify(err.response.data.msg);
          }
        );
      });
    }
    props.setRefresh(prev => !prev)
    setGeneratedSuccessfully(true)
    successMessageNodify("Successfully Created Matches");
  }

  const generateMatch = () => {
    createMatchesForSport(groupId);
  }


  useEffect(() => {
    ApiGetAuth(`games/get-gameDetails/${groupId}`).then(
      (data) => {
        if (data?.data) {
          setValues({ ...data.data[0], game_bracket_id: 3 })
          setGroupDetails(data.data[0])
        }
      }
    );
  }, [])


  return (
    <>
      <div className="modal  generatematch season-popup" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered   modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel ">Generate Game (Round Robin ) </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.handleClose}></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="form-group col-md-12 ">
                  {generatedSuccess == true ? <>
                    <p className='text-center text-success px-3 '> Games have been Generated Successfully please go to the Draft section. <p style={{ "cursor": 'pointer', "text-decoration": "underline" }} onClick={() => { props.tabActive('Draft'); props.handleClose(); }}> Click here to go to Draft </p></p>
                  </> : <p className='text-center generate-text px-3 '> By Clicking Generate , Games has been generated and shown in draft. where you can update changes.</p>}
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-center pt-0">
              <button type="button" className="btn btn-primary" onClick={generateMatch} disabled={props.loader}>
                {props.loader ? <FontAwesomeIcon icon={faCircleNotch} spin /> : "Generate"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GenerateMatch;
