import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ApiGetAuth, Apipost } from '../../../helpers/apiCall';
import { errorMessageNodify, successMessageNodify } from '../../../helpers/notify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import withLoader from '../../../components/PageLoader';


const validationSchema = Yup.object().shape({
    firstname: Yup.string()
    .required('First Name cannot be empty')
    .matches(/^[a-z ,.'-]+$/i, 'Please enter a valid firstname')
    .min(3, 'First Name must be at least 3 characters long')
    .max(30, 'First Name cannot be longer than 30 characters'),
    lastname: Yup.string()
    .required('Last Name cannot be empty')
    .matches(/^[a-z ,.'-]+$/i, 'Please enter a valid lastname')
    .min(3, 'Last Name must be at least 3 characters long')
    .max(30, 'Last Name cannot be longer than 30 characters'),
    email: Yup.string()
        .required('Email address cannot be empty')
        .email('You have entered an invalid email address!'),
    // role: Yup.string()
    //     .required('Please Select the Role'),
    password: Yup.string()
        .required('Password cannot be empty')
        .matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            'Password should be at least eight characters, including one letter, one number, and one special character'
        )
        .min(8, 'Password must be at least 3 characters long')
        .max(30, 'Password cannot be longer than 30 characters'),
        
});



const RegisterwithoutLoader = () => {
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);
    const [roleList, setRoleList] = useState([]);
	const [loading, setLoading] = useState(false);
    const [isVisible, setVisible] = useState(true);
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const formik = useFormik({
        initialValues: {
            // role: '',
            firstname: '',
            lastname: '',
            email: '',
            password: '',
        },
        validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            Apipost('/auth/signup', values)
                .then((response) => {
                    let result = response.data;
                    if (result.status === true) {
                        successMessageNodify(result.msg);
                        navigate('/verify-otp', { state: { id: result.data } });
                    } else {
                        errorMessageNodify(result.msg);
                        setLoading(false);
                        return;
                    }
                  
                })
                .catch((err) => {
                    errorMessageNodify(err.response.data.message);
                    setLoading(false);
                    return;
                });
        },
    });


    const handleChecked = event => {
        setIsChecked(current => !current);
    };

	const toggle = () => {
		setVisible(!isVisible);
	};
    const handlePasswordChange = (event) => {
        const value = event.target.value;
        setPassword(value);
    
        // Set a fixed length for the password (e.g., 8 characters)
        if (value.length !== 8) {
            setErrorMessage('Password must be exactly 8 characters long.');
        } else {
            setErrorMessage('');
        }
    };
    
    return (
        <div>
            <div id="register-page">
                <div className="login-form">
                    <div className="login-form-inner">
                        <div className="login-logo">
                            <img src={require('../../../assets/images/GIU_logo.png')} alt="Giu" />
                        </div>
                        <div className="login-form-box">
                            <form onSubmit={formik.handleSubmit}>
                                

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-2">
                                            <label>First Name</label>
                                            <input
                                            maxLength={30}
                                                type="text"
                                                id="firstname"
                                                className="form-control"
                                                placeholder="First Name"
                                                {...formik.getFieldProps('firstname')}
                                            />
                                            {formik.touched.firstname && formik.errors.firstname && (
                                                <span className="text-danger">{formik.errors.firstname}</span>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-2">
                                            <label>Last Name</label>
                                            <input
                                             maxLength={30}
                                                type="text"
                                                id="lastname"
                                                className="form-control"
                                                placeholder="Last Name"
                                                {...formik.getFieldProps('lastname')}
                                            />
                                            {formik.touched.lastname && formik.errors.lastname && (
                                                <span className="text-danger">{formik.errors.lastname}</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-2">
                                    <label>E-mail</label>
                                    <input
                                     maxLength={35}
                                        type="text"
                                        id="email"
                                        className="form-control"
                                        placeholder="Email"
                                        {...formik.getFieldProps('email')}
                                    />
                                    {formik.touched.email && formik.errors.email && (
                                        <span className="text-danger">{formik.errors.email}</span>
                                    )}
                                </div>
                                <div className="mb-1">
                                    <label>Password</label>
                                    <div className=" form-group passord-form-group">
                                    <input
                                     maxLength={30}
                                        type={isVisible ? "password" : "text"}
                                        id="contact"
                                        className="form-control"
                                        placeholder="Password"
                                        {...formik.getFieldProps('password')}
                                    />
                                    <span className="icon Password-hide" onClick={toggle}>
											{isVisible ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye} />}
										</span>
                                        </div>
                                    {formik.touched.password && formik.errors.password && (
                                        <span className="text-danger">{formik.errors.password}</span>
                                    )}
                                </div>
                                {/* <div className="mb-1">
                                    <label> Select Role</label>
                                    <select 
                                        type="role"
                                        id="role"
                                        {...formik.getFieldProps('role')}
                                        className="form-control"
                                        placeholder="role">
                                        <option value=''>Select</option>
                                         {roleList??.map(item=>(
                                        <option value={item.id}>{item.role_name}</option>
                                         ))}
                                    </select>
                                    {formik.touched.role && formik.errors.role && (
                                        <span className="text-danger">{formik.errors.role}</span>
                                    )}
                                </div> */}
                                <div className="mb-4 mt-4 forgot-password">
                                    <label className="custom-check">
                                        Yes, I understand and agree to the GIU TeamConnect Terms of Service, including the User Agreement and Privacy Policy.
                                        <input type="checkbox" checked={isChecked} onChange={handleChecked} />
                                        <span className="checkmark"></span>
                                    </label>
                                </div>

                                <div className="mb-3">
                                    <button className="btn btn-dark green full-btn btn-lg signin-btn" disabled={!isChecked} type="submit">
                                    {
										loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Create Account'
									}
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div className="form-bottom-link">
                            Already have an account? <Link to="/"><b>Login Now</b></Link>
                        </div>
                    </div>
                </div>
                <div className="login-img">
                    <div className="login-img-inner">
                        <div className="dashboard-title">
                            <h3>WELCOME TO</h3>
                            <h1>Organizer Panel</h1>
                            <p>Register to Access Dashboard</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) ;
} ;

const Register = withLoader(RegisterwithoutLoader)

export default Register;
