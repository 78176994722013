import React from "react";
import {Route,useNavigate } from "react-router-dom";
import Loginpage from '../pages/Auth/Login/login';
import VerifyOtp from '../pages/Auth/Login/verifyOtp';
import ForgotPassword from '../pages/Auth/ForgotPassword/index';
import Register from '../pages/Auth/Register/index.js';
import SportsList from '../pages/Auth/Register/SportsList';
import LeagueBilling from "../pages/LeagueBilling/Index.js";
import Completion from "../components/Payment/Complete.js";
import LeagueBillingList from "../pages/PaymentBilling/index.js";
import PaymentPage from "../pages/Paymentpage/index.js";
import History from "../pages/History/index.js";

export const PageNotFound = () => {
    const history = useNavigate();
  
    const handleGoBack = () => {
      history('/');
    };
  
    return (
        <section className="page_404">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="col-sm-10 col-sm-offset-1  text-center ">
                <div className="four_zero_four_bg">
                  <h1 className="text-center ">404</h1>
                </div>
                <div className="contant_box_404">
                  <h3 className="h2">Look like you're lost</h3>
                  <p>the page you are looking for not available!</p>
                  <button onClick={()=>handleGoBack()} className="link_404">
                    Go to Home
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>      
    );
  };

export default [
    <>
        <Route  forceRefresh={true} index element={<Loginpage />} />
        <Route  forceRefresh={true} path='/forgot-password' element={<ForgotPassword />} />
        <Route  forceRefresh={true} path='/verify-otp' element={<VerifyOtp />} />
        <Route  forceRefresh={true} path='/sports-list' element={<SportsList/>}  />
        <Route  forceRefresh={true} path='/register' element={<Register />} />
        <Route  forceRefresh={true} path='/sports-list' element={<SportsList />} />
        {/* <Route  forceRefresh={true} path='/league-billing' element={<LeagueBilling/>}/> */}
        <Route  forceRefresh={true} path='/league-billings' element={<LeagueBillingList/>}/>
        <Route  forceRefresh={true} path='/league-billings-history' element={<History/>}/>
        <Route  forceRefresh={true} path='/league-billings-details' element={<PaymentPage/>}/>
        <Route   path='/league-billing/payment-Success' element={<Completion/>}/>
        <Route  forceRefresh={true} path='*' element={<PageNotFound path='/' />}/>
    </>
];