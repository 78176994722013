import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ApiGet, Apipost } from '../../../helpers/apiCall';
import { errorMessageNodify, successMessageNodify } from '../../../helpers/notify';
import { useDispatch } from 'react-redux';
import {  fetchVenues, fetchSports, fetchGrades,fetchAgeGroup, fetchCoaches, fetchProfileDetails } from "../../../redux/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { isAuthenticated } from '../../../redux/actions';

const SportsList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [sportsList, setSportsList] = useState([]);
    const [selectedSports, setSelectedSports] = useState([]);
	const [loading, setLoading] = useState(false);
    var role = localStorage.getItem("role");

    const dispatch = useDispatch();

    const fromPage =  `/organizer/dashboard`;
    useEffect(() => {
        ApiGet("games/get-sports").then(
            (data) => {
                setSportsList(data.data);
            }
        );
    }, []);

    const handleChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setSelectedSports((prev) => [...prev, value])
        }
        else {
            setSelectedSports(selectedSports.filter((e) => e !== value))
        }

    };

    const handleSubmit = () => {

        setLoading(true);
        Apipost('/auth/add-sports-organizer', { id: JSON.stringify(selectedSports) }, "application/x-www-form-urlencoded", true).then(
            (response) => {
                let result = response.data;
                if (result.status === true) {
                    successMessageNodify(result.msg);
                    dispatch(fetchVenues())
                    dispatch(fetchSports())
                    dispatch(fetchCoaches())
                    dispatch(fetchGrades())
                    dispatch(fetchAgeGroup())
                    dispatch(fetchProfileDetails())
                    dispatch(isAuthenticated(true))
                    setTimeout(() => navigate(fromPage,{replace:true}), 500)
                } else {
                    errorMessageNodify(result.msg);
                    setLoading(false);
                    return;
                }

            },
            (err) => {
                errorMessageNodify(err.response?.data.message);
                setLoading(false);
				return;
            }
        );
    }

    return (
        <div>
            <div id="verification-page">
                <div className="container">

                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6">
                            <div className="continue-btn sportlist">
                                <div className="verification-logo">
                                    <img alt="" src={require("../../../assets/images/GIU_logo.png")} />
                                </div>
                                <div className="panel_sports create-form-inner mt-4">
                                    <p>Choose your sport to get started</p>
                                    <div className="form-group mb-3">
                                    <div className="row ">
                                          {sportsList?.length ? (
                                              sportsList?.map((item, i) => (
                                                  <div key={i} className="col-md-6 mb-3 " >
                                                      <img src={item.icon} alt='sports icon missing' width='30' className='mx-2'   />
                                                     <span>{item.sports_name} </span> 
                                                      <label className="custom-check mx-2 " >
                                                          <input
                                                              type="checkbox"
                                                              name="day"
                                                              value={item.id}
                                                              onChange={handleChange}
                                                          />
                                                          <span className="checkmark"></span>
                                                      </label>
                                                  </div>
                                              ))
                                          ) : (
                                              <></>
                                          )}
                                      </div>
                                    </div>
                                </div>
                                <button className="btn btn-dark green payment-btn" onClick={handleSubmit}>{loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Save'}</button>
                            </div>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                    <div className="also-contact">You can also contact on <a href="mailto:admin@whatson.ca">admin@giu.com</a></div>
                </div>
            </div>
        </div>
    );
};

export default SportsList;