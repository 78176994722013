import React, { useEffect, useState } from 'react';
import { ApiGet, Apipost } from '../../helpers/apiCall';
import Payment from '../../components/Payment';
import moment from 'moment';
import { useLocation, useNavigate } from 'react-router';
import '../../assets/css/style.css'
import { Loader } from '../../assets/Loader/Loader';
import check from '../../assets/images/check1.svg'

function formatData(data) {
  if(data?.length == 0 ) return []
  const formattedData = [];
  const leagueGroup = {};
  data?.forEach((entry) => {
    const { game_title, season_name, group_name, player, current_price, player_email ,plans,plan_date} = entry;
    if (!leagueGroup[game_title]) leagueGroup[game_title] = {};
    if (!leagueGroup[game_title][season_name]) leagueGroup[game_title][season_name] = [];
    leagueGroup[game_title][season_name].push({
      divisionName: group_name,
      player: { name: player, price: current_price, player_email, plans ,plan_date},
    });
  });

  Object.keys(leagueGroup).forEach((leagueName) => {
    const leagueData = { leagueName, season: Object.keys(leagueGroup[leagueName])[0], divisions: [] };
    Object.keys(leagueGroup[leagueName]).forEach((season) => {
      const divisionsMap = {};
      leagueGroup[leagueName][season].forEach(({ divisionName, player }) => {
        if (!divisionsMap[divisionName]) divisionsMap[divisionName] = [];
        divisionsMap[divisionName].push({ name: player.name, price: player.price, email: player.player_email,plan:player.plans,plan_date:player.plan_date });
      });

      Object.keys(divisionsMap).forEach((divisionName) => {
        leagueData.divisions.push({ divisionName, players: divisionsMap[divisionName] });
      });
    });

    formattedData.push(leagueData);
  });

  return formattedData;
}

const LeagueBillingList = () => {
  const [leagues, setLeagues] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState({});
  const [charges, setCharges] = useState({});
  const [showCardEle, setShowCardEle] = useState(false);
  const [rawData, setRawData] = useState([]);
  const [paidOrders, setPaidOrders] = useState([]);
  const [paymentIntent , setPaymentIntent] = useState('')
  const location = useLocation();
  const [step , setStep] = useState(1)
  const [email, setEmail] = useState(null);
  const [loader, setLoader] = useState(false);
  const [successLoader , setSuccessLoader] = useState(true)
  const [orderDetails , setOrderDetails] = useState({})
  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);
    const query = new URLSearchParams(location.search);
    const email = query.get('pid');
    setEmail(email);

    if (email?.length > 0) {
      ApiGet(`payment/getPlayerPayment?email=${email}`).then((data) => {
        const formattedLeagues = formatData(data.data);
        setLeagues(formattedLeagues);
        setCharges(data.charges);
        setRawData(data.data);
        if(formattedLeagues.length == 0 && data.paidplayers.length >0 ) 
        setPaidOrders(data.paidplayers);
        const initialSelection = {};
        formattedLeagues.forEach((league) => {
          league.divisions.forEach((division) => {
            division.players.forEach((player) => {
              initialSelection[player.email] = true;
            });
          });
        });
        setSelectedPlayers(initialSelection);
        setLoader(false);
      });
    } else {
      navigate('/');
    }
  }, []);
  useEffect(()=>{
    const fetchData = async () => {
      try {
        if(paymentIntent){
          setSuccessLoader(true)
          const data = await Apipost("/payment/update-Player-Payment", { client_secret: paymentIntent.client_secret, paymentIntent: paymentIntent }, "application/x-www-form-urlencoded", true);
          setOrderDetails(data.data.data);
          setSuccessLoader(false)
        }
        // setIsLoading(false);
      } catch (error) {
        console.error("Error fetching payment details:", error);
        // setIsLoading(false);
      }
    }
    fetchData();
  },[paymentIntent])
  


  const handlePlayerSelect = (email, isSelected) => {
    setSelectedPlayers((prevSelected) => {
      const updatedSelection = { ...prevSelected };
      if (isSelected) updatedSelection[email] = true;
      else delete updatedSelection[email];
      return updatedSelection;
    });
  };

  const calculateOverallTotals = () => {
    let total = 0;
    leagues.forEach((league) =>
      league.divisions.forEach((division) =>
        division.players.forEach((player) => {
          if (selectedPlayers[player.email]) total += player.price;
        })
      )
    );

    const taxPercentage = parseFloat(charges.tax) || 0;
    const transactionChargesPercentage = parseFloat(charges.transaction_charges) || 0;

    const tax = (taxPercentage / 100) * total;
    const transactionCharges = (transactionChargesPercentage / 100) * total;
    const grandTotal = total + tax + transactionCharges;

    return { total, tax, transactionCharges, grandTotal };
  };

  const handleShowPaymentCard = () => {
    const temp = Object.keys(selectedPlayers).flatMap((email) =>
      rawData.filter((item) => item.player_email === email)
    );
    setRawData(temp);
    setShowCardEle(true);
  };

  const { total, tax, transactionCharges, grandTotal } = calculateOverallTotals();
  return (<>
  {loader ? <><Loader/></> :<>
{
<>
  <main className="wrapper">
  <section className="payments my-3 ">
    <div className="container ">
      <div className="payments__content bg-lightBlue p-4 br-20">
        <div className="row mb-2">
          <div className="col-6">
            <h4 className="text-start">Total Payment</h4>
            <p className="text-primary f-26 font-bold font-inter">${grandTotal.toFixed(2)}</p>
          </div>
          <div className="col-6 d-flex justify-content-end align-items-center">
            <div className="button">
              <button className="btn btn-orange btn-padding text-white border-radius-lg" disabled={grandTotal === 0 || showCardEle} onClick={handleShowPaymentCard}>
                Pay Now
              </button>
            </div>
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-9 text-secondary fw-500 f-12">Total</div>
          <div className="col-3 text-secondary fw-500 f-12 text-end">
            ${total.toFixed(2)}
          </div>
        </div>
        <div className="row mb-1">
          <div className="col-9 text-secondary fw-500 f-12">Tax ({charges.tax}%)</div>
          <div className="col-3 text-secondary fw-500 f-12 text-end">${tax.toFixed(2)}</div>
        </div>
        <div className="row mb-1">
          <div className="col-9 text-secondary fw-500 f-12">
          Transaction Charges ({charges.transaction_charges}%)
          </div>
          <div className="col-3 text-secondary fw-500 f-12 text-end">${transactionCharges.toFixed(2)}</div>
        </div>
        <div className="row mb-1">
          <div className="col-9 text-dark fw-500 f-12">Grand Total</div>
          <div className="col-3 text-dark fw-500 f-12 text-end">${grandTotal.toFixed(2)}</div>
        </div>
      </div>
      <p className="f-12 text-secondary mt-3">
        <span className="text-orange fw-600">Note:</span> The price may vary
        based on season’s plan and is determined by the current date and plans
        last date.
      </p>
    </div>
  </section>
  {leagues?.map((league, index) => (
    <section className="tour-list position-relative mt-5">
    <div className="position-absolute american-football">
      <img src="images/american-football 1.png" alt="" />
    </div>
    <div className="container">
      <div className="tour-lists-content p-4 pb-0 border">
        <div className="headings mb-2">
          <p>
            <strong>{league.leagueName}</strong>
            <span className="f-10 text-grey">(Tournament)</span>
          </p>
        </div>
        {
          league?.divisions.map(division=>{
            return <><div className="row">
            <div className="col-6">{league.season}</div>
            <div className="col-6 text-end">
              <span className="badge rounded-pill bg-success fw-500">
               {division.divisionName}
              </span>
            </div>
            {
              division?.players.map(player=>{
                return <div className="row border-card py-3">
          <div className={moment(player.plan_date).diff(moment(), 'days') <= 7?" col-12 text-orange fw-500 f-12 text-danger":"col-12 text-orange fw-500 f-12 text-success"} >
            {player.plan_date}{" "}
            <span className="ms-2 infoIcon">
              <img src="images/info.svg" alt="" />
            </span>
          </div>
          <div className="col-6">
            <h6 className="fw-500">{player.name}</h6>
            <p className="f-12 fw-400 text-grey">{player.email}</p>
          </div>
          <div className="col-4">
            <p className="f-12 fw-400 text-grey">{player.plan}</p>
            <p className="f-12 fw-400 text-grey">${player.price}</p>
          </div>
          <div className="col-2 d-flex justify-content-center align-items-center">
            <input  disabled={showCardEle} type="checkbox" checked={!!selectedPlayers[player.email]} onChange={(e) => handlePlayerSelect(player.email, e.target.checked)} className="checkbox" />
          </div>
        </div>
              })
            }
          </div></>
          })
        }
      </div>
    </div>
  </section>
    ))}
    {showCardEle && leagues.length > 0 &&(<>
    {
      step ==1 ?<><Payment
      price={grandTotal.toFixed(2)}
      value={rawData}
      email={email}
      setStep={setStep}
      setPaymentIntent={setPaymentIntent}
      baseUrl="/payment/create-player-payment-intent"/></>:<>{
     successLoader ? <Loader/> :
      <section className="confirmation p-2 pt-5 shadow-lg wrapper">
    <div className="container">
      <div className="confirmaion_heading ">
        <div className="confimation_image-wrapper mx-auto">
          <img src={check} alt="" />
        </div>
        <p className="text-orange text-center">Payment received successfully</p>
      </div>
      <div className="row pt-4 pb-3">
        <div className="col-6 text-start">Amout Paid</div>
        <div className="col-6 text-end">${orderDetails.payment_amount/100}</div>
      </div>
      <div className="row pt-4 pb-3">
        <div className="col-6 text-start">Payment Id</div>
        <div className="col-6 text-end">{orderDetails.stripe_payment_id}</div>
      </div>
      <div className="row pt-4 pb-3">
        <div className="col-6 text-start">Date</div>
        <div className="col-6 text-end">{moment(orderDetails.payment_date).format('MMMM Do YYYY')}</div>
      </div>
    </div>
  </section>}
      </>
    }
     </>)}
</main>
  </>
}</>}</>
  );
};

export default LeagueBillingList;
