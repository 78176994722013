import React, { useEffect, useState } from 'react';
import '../../assets/css/style.css';
import { ApiGet } from '../../helpers/apiCall';
import { useLocation } from 'react-router';
import { Loader } from '../../assets/Loader/Loader';
import moment from 'moment';

function formatData(data) {
  const result = [];
  data.forEach(item => {
    let league = result.find(
      l => l.leagueName === item.game_title && l.season === item.season_name
    );

    if (!league) {
      league = {
        leagueName: item.game_title,
        season: item.season_name,
        divisions: []
      };
      result.push(league);
    }

    let division = league.divisions.find(
      d => d.divisionName === item.group_name
    );

    if (!division) {
      division = {
        divisionName: item.group_name,
        players: []
      };
      league.divisions.push(division);
    }

    division.players.push({
      name: item.player,
      email: item.player_email,
      amount: parseFloat(item.player_charged_amount),
    });
  });

  return result;
}

const PaymentPage = () => {
  const [loader, setLoader] = useState(false);
  const [total, setTotal] = useState({});
  const [data, setData] = useState([]);
  const location = useLocation();

  useEffect(() => {
    setLoader(true);
    const query = new URLSearchParams(location.search);
    const id = query.get('id');

    if (id?.length > 0) {
      ApiGet(`payment/getPlayerPayment?id=${id}`).then(data => {
        if (data.data.length) {
          
          setTotal({...data.data[0], name:data.name});
          setData(formatData(data.data));
        }
        setLoader(false);
      });
    } else {
      setLoader(false);
    }
  }, [location]);
console.log(total , "total")
  // Calculate Total Amount from Players
  const calculateTotalAmount = () => {
    let sum = 0;
    data.forEach(league => {
      league.divisions.forEach(division => {
        division.players.forEach(player => {
          sum += player.amount;
        });
      });
    });
    return sum;
  };

  // Dynamically calculate totals
  const totalAmount = calculateTotalAmount();
  const taxPaidPercentage = parseFloat(total.tax_paid || 0);
  const transactionChargePercentage = parseFloat(total.transaction_charge_paid || 0);
  const taxAmount = (taxPaidPercentage / 100) * totalAmount;
  const transactionChargeAmount = (transactionChargePercentage / 100) * totalAmount;

  return (
    <>
      <main className="wrapper">
        {loader ? (
          // Loader Content
          <div className="loading-text">
           <Loader/>
          </div>
        ) : (
          // Main Content
          <>
            <section className="payments my-3">
              <div className="container">
                <div className="payments__content bg-lightBlue p-4 br-20">
                  <div className="row mb-2 border-bottom pb-2">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-6 d-flex align-items-center justify-content-start">
                          <h6 className="mb-0 fw-600">{total.name}</h6>
                        </div>
                        <div className="col-6 text-end">
                          <h3 className="text-primary">
                            ${(totalAmount + taxAmount + transactionChargeAmount).toFixed(2)}
                          </h3>
                        </div>
                      </div>
                      <div className="row f-12 mb-1">
                        <div className="col-6">
                          <p className="text-grey">Transaction ID</p>
                        </div>
                        <div className="col-6 text-end">
                          <button className="btn rounded-pill rounded px-3 py-0 f-10 btn-outline-success">
                            Paid
                          </button>
                        </div>
                      </div>
                      <div className="row f-12">
                        <div className="col-6">
                          <p>{total.stripe_payment_id}</p>
                        </div>
                        <div className="col-6 text-end">
                          <p className="text-grey">
                          <span>{moment(total.date_of_payment).format('MMMM Do YYYY')}</span>
                          <span className="mx-2">{moment(total.date_of_payment).format('h:mm:ss a')}</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col-9 text-secondary fw-500 f-12">Total</div>
                    <div className="col-3 text-secondary fw-500 f-12 text-end">
                      ${totalAmount.toFixed(2)}
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col-9 text-secondary fw-500 f-12">
                      Tax ({taxPaidPercentage}%)
                    </div>
                    <div className="col-3 text-secondary fw-500 f-12 text-end">
                      ${taxAmount.toFixed(2)}
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col-9 text-secondary fw-500 f-12">
                      Transaction Charges ({transactionChargePercentage}%)
                    </div>
                    <div className="col-3 text-secondary fw-500 f-12 text-end">
                      ${transactionChargeAmount.toFixed(2)}
                    </div>
                  </div>
                  <div className="row mb-1">
                    <div className="col-9 text-dark fw-500 f-12">Grand Total</div>
                    <div className="col-3 text-dark fw-500 f-12 text-end">
                      ${(totalAmount + taxAmount + transactionChargeAmount).toFixed(2)}
                    </div>
                  </div>
                </div>
                <p className="f-12 text-secondary mt-3">
                  <span className="text-orange fw-600">Note:</span> The price may
                  vary based on season’s plan and is determined by the current date
                  and plans last date.
                </p>
              </div>
            </section>
            {data?.map((league, key) => (
              <section className="tour-list position-relative mt-5" key={key}>
                <div className="position-absolute american-football">
                  <img src="images/american-football 1.png" alt="" />
                </div>
                <div className="container">
                  <div className="tour-lists-content p-4 pb-0 border br-20">
                    <div className="headings mb-2">
                      <p>
                        <strong>{league.leagueName}</strong>
                        <span className="f-10">(Tournament)</span>
                      </p>
                    </div>
                    {league.divisions.map((division, divKey) => (
                      <div key={divKey}>
                        <div className="row">
                          <div className="col-6">{league.season}</div>
                          <div className="col-6 text-end">
                            <span className="badge rounded-pill bg-success fw-500">
                              {division.divisionName}
                            </span>
                          </div>
                        </div>
                        {division.players.map((player, playerKey) => (
                          <div className="row border-card py-3" key={playerKey}>
                            <div className="col-12 text-orange fw-500">09-12-24</div>
                            <div className="col-6">
                              <h6 className="fw-500">{player.name}</h6>
                              <p className="f-12">{player.email}</p>
                            </div>
                            <div className="col-4">
                              <p className="f-12">Amount Paid</p>
                              <p className="f-12">${player.amount.toFixed(2)}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </section>
            ))}
          </>
        )}
      </main>
    </>
  );
};

export default PaymentPage;
