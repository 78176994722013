/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { Row, Col, Table, Form, Button } from 'react-bootstrap';
import sunLogo from '../../../src/assets/images/sun-games.jpg';
import logo from '../../../src/assets/images/logo-n.png';
import { usePDF } from 'react-to-pdf';
import { useLocation, useParams } from 'react-router';
import { ApiGetAuth, Apipost } from '../../helpers/apiCall';
import { useSelector } from 'react-redux';
import { errorMessageNodify, successMessageNodify } from '../../helpers/notify';
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from 'moment/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { generateImage } from '../../helpers/helperfunctions';



const RosterSheet = () => {
    const { seasonId, seasonName } = useParams()
    const [fileName, setFileName] = useState(`${seasonName}_Roster_${moment().format('YYYYMMDD_HHmmss')}.pdf`)
    const { toPDF, targetRef } = usePDF({ filename: fileName });
    const [selectedAlternateLogo, setSelectedAlternateLogo] = useState(null); // Initial state for the alternate logo
    const [roasterLogoFile, setRoasterLogoFile] = useState(null)
    const [players, setPlayers] = React.useState([]);
    const [selectedDivision, setSelectedDivision] = React.useState(null);  // State for selected division object
    const [selectedTeam, setSelectedTeam] = React.useState(null);
    const [divisions, setDivisions] = React.useState([]);     // State for selected team object
    const [teams, setTeams] = React.useState([]);
    const [history, setHistory] = useState([])
    const [loader, setLoader] = useState(false);
    const [playerImages, setPlayerImages] = useState({});
    const [showImages, setShowImages] = useState(false);
    const [blocked, setBlocked] = useState(0)
    const location = useLocation()
    const [initialValues, setInitialValues] = useState({
        subject: '',
        districtPresident: '',
        leaguePresident: '',
        message: ''
    })

    const [jerseyNumbers, setJerseyNumbers] = useState([]);

    useEffect(() => {
        // Initialize jerseyNumbers state when players are loaded
        setJerseyNumbers(players.map(player => player.jersey_no || ''));
    }, [players]);

    const handleJerseyChange = (index, value) => {
        const updatedJerseyNumbers = [...jerseyNumbers];
        updatedJerseyNumbers[index] = value;
        setJerseyNumbers(updatedJerseyNumbers);
    };

    const handleBlur = (index, playerId) => {
        handleAddJersyNo(playerId, jerseyNumbers[index]);
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);

        // Check if the 'block' parameter is set to 'true'
        if (params.get('block') > 0) {
            setBlocked(Number(params.get('block')))
            getHistory(Number(params.get('block')))
        } else {
            handleGetDivisions()
            getHistory()
        }
    }, [loader, location.search, seasonId])


    const handleCheckboxChange = () => {
        setShowImages((prev) => !prev);
    };
    const handleImageUpload = (event, playerId) => {
        const file = event.target.files[0];
        if (file) {
            handleProfileImageUpload(playerId, file)
            const reader = new FileReader();
            reader.onload = () => {
                // Update the playerImages with the uploaded image
                setPlayerImages((prevImages) => ({
                    ...prevImages,
                    [playerId]: reader.result,
                }));
            };
            reader.readAsDataURL(file);
        }
    };
    const allMembers = useSelector(state => state.COACHES)
    const ref = useRef();
    const handleGetDivisions = () => {
        ApiGetAuth(`games/game-groups/${seasonId}`).then(
            (data) => {
                if (data?.data) {
                    setDivisions(data.data);
                }
            }
        );
    }
    const getHistory = (id = 0) => {
        ApiGetAuth(`season/get-roaster-history?id=${id}`).then(
            (data) => {
                if (data?.data) {
                    setHistory(data.data);
                    if (id > 0) {
                        const { team, players, logo, emaildetails } = data.data;
                        const { subject, district_president, league_president, message } = emaildetails[0]
                        setSelectedTeam(team);
                        setPlayers(players);
                        setSelectedAlternateLogo(logo);
                        setInitialValues({
                            subject,
                            districtPresident: district_president,
                            leaguePresident: league_president,
                            message
                        })
                    }
                }
            }
        );
    }
    const validationSchema = Yup.object().shape({
        subject: Yup.string().required("Subject is required."),
        districtPresident: Yup.string().required("Please select a District President."),
        leaguePresident: Yup.string().required("Please select a League President."),
        message: Yup.string().required("Message is required."),
    });

    const handleFormSubmit = async (values, { resetForm }) => {
        setLoader(true)
        const formData = new FormData();
        formData.append('logo', roasterLogoFile);
        formData.append('subject', values.subject);
        formData.append('districtPresident', values.districtPresident);
        formData.append('leaguePresident', values.leaguePresident);
        formData.append('message', values.message);
        formData.append('seasonId', seasonId);
        formData.append('DivisionId', selectedDivision.id);
        formData.append('TeamId', selectedTeam.id);

        try {
            const success = await Apipost('/season/send-roaster', formData, "multipart/form-data", true);
            setLoader(false)
            successMessageNodify(success.data.msg);
        } catch (error) {
            errorMessageNodify("Failed To Send")
            console.error('Upload failed:', error);
            setLoader(false)
        }
    };



    const handleAddJersyNo = (id, jersyid) => {
        if (id && jersyid && blocked === 0) {
            ApiGetAuth(`teams/add-Jersyno-InTeams?id=${id}&&jersey_no=${jersyid}`).then(
                (data) => {
                    successMessageNodify(data.message)
                }
            );
        }

    }


    // Handle file upload for the alternate logo
    const handleFileUploadAlternate = (event) => {
        const file = event.target.files[0];
        if (file) {
            setRoasterLogoFile(file)
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedAlternateLogo(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };


    const handleGetTeams = (divisionId) => {
        const selectedDivObj = divisions.find(div => div.id === parseInt(divisionId));  // Find the full division object
        setSelectedDivision(selectedDivObj);  // Set the full selected division object
        if (divisionId > 0) {
            ApiGetAuth(`games/get-gameDetails/${divisionId}`).then(
                (data) => {
                    if (data?.data) {
                        setTeams(data?.data[0].teams);
                        setPlayers([])
                    }
                }
            );
        }
    };

    const handleProfileImageUpload = (id, file) => {
        Apipost(
            "/teams/update-player-profile",
            { image: file, id },
            "multipart/form-data",
            true
        ).then(
            (success) => {
                console.log(success, "success ")
                successMessageNodify(success?.data?.message)
            },
            (err) => {
                errorMessageNodify(err?.response?.data.message);

            }
        );
    }

    const handleGetPlayers = (teamId) => {
        setShowImages(false)
        setPlayerImages({})
        const selectedTeamObj = teams.find(team => team.id === parseInt(teamId));  // Find the full team object
        setSelectedTeam(selectedTeamObj);  // Set the full selected team object
        setFileName(`${seasonName}_${selectedDivision.group_name}_${selectedTeamObj.team_name}_${moment().format('YYYYMMDD_HHmmss')}.pdf`)
        if (teamId > 0) {
            ApiGetAuth(`games/get-players?id=${teamId}&groupid=${selectedDivision.id}`).then(
                (data) => {
                    if (data?.players) {
                        setPlayers(data.players);
                    }
                }
            );
        }
    };
    return (
        <>
            <div className="container-fluid">
                <div className="roaster-top-nav  text-white py-3" style={{ backgroundColor: '#484848' }}>
                    <div className='px-4 '>
                        <Row >
                            <Col md={3}>
                            </Col>
                            <Col md={6}>
                                <div className='text-center d-flex  justify-content-center align-items-center' onClick={() => toPDF()} style={{ cursor: 'pointer' }}>

                                    <h4 className="mb-0 ">Certified Roster and Minimum Play Sheet</h4>
                                    <span> <svg width="60px" height="30px" viewBox="0 0 24 24" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 3.01254C10.9983 2.46026 11.4446 2.01114 11.9969 2.00941C12.5492 2.00768 12.9983 2.45399 13 3.00627L11 3.01254Z" fill="#fff" />
                                        <path d="M14.3158 10.2951L13.0269 11.592L13 3.00627L11 3.01254L11.0269 11.5983L9.73003 10.3095C9.33828 9.92018 8.7051 9.92214 8.3158 10.3139C7.9265 10.7056 7.92849 11.3388 8.32024 11.7281L8.32275 11.7306L8.32374 11.7316L12.039 15.4236L15.7206 11.7187L15.7262 11.7131L15.727 11.7123L15.7278 11.7115L15.7337 11.7056L15.7344 11.7049L14.3158 10.2951Z" fill="#fff" />
                                        <path d="M15.7344 11.7049C16.1237 11.3131 16.1217 10.6799 15.73 10.2906C15.3382 9.90134 14.705 9.90335 14.3158 10.2951L15.7344 11.7049Z" fill="#fff" />
                                        <path d="M4 12C4 10.8954 4.89543 10 6 10C6.55228 10 7 9.55228 7 9C7 8.44771 6.55228 8 6 8C3.79086 8 2 9.79086 2 12V18C2 20.2091 3.79086 22 6 22H17C19.7614 22 22 19.7614 22 17V12C22 9.79086 20.2091 8 18 8C17.4477 8 17 8.44771 17 9C17 9.55228 17.4477 10 18 10C19.1046 10 20 10.8954 20 12V17C20 18.6569 18.6569 20 17 20H6C4.89543 20 4 19.1046 4 18V12Z" fill="#fff" />
                                    </svg></span>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className="mb-0 text-end">
                                    <Button variant="outline-light">Roster Notification</Button>{' '}
                                </div>


                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <Row className='mt-5'>

                <Col xxl={9} xl={8} lg={8} md={8} sm={8}>
                    {
                        blocked > 0 ? null : <div className='Upload_Logo_Roster bg-white  mb-4 mt-5'>
                            <Row className=" p-3 mt-5">
                                <Col md={3}>
                                    <div className=" ">
                                        <div>
                                            <div>
                                                <select className="form-select form-select py-2" aria-label="Select Division" onChange={(e) => handleGetTeams(e.target.value)}>
                                                    <option value={0}>Select Division</option>
                                                    {divisions.map(item => <option key={item?.id} value={item?.id}>{item?.group_name}</option>)}
                                                </select>
                                            </div>

                                        </div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className=" ">
                                        <div>
                                            <select className="form-select form-select py-2" aria-label="Select Teams" onChange={(e) => handleGetPlayers(e.target.value)} disabled={teams.length === 0}>
                                                <option value={0}>Select Teams</option>
                                                {teams.map(item => <option key={item?.id} value={item?.id}>{item?.team_name}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    {players.length > 0 ?
                                        <Form.Check
                                            type="checkbox"
                                            label="Display player's picture in Roaster Sheet"
                                            checked={showImages}
                                            onChange={handleCheckboxChange}
                                        /> : null}
                                </Col>

                                <Col md={3}>
                                    <div className='text-center'>
                                        <div>
                                            <input
                                                type="file"
                                                id="logoUploadAlternate"
                                                style={{ display: 'none' }}
                                                accept="image/*"
                                                onChange={handleFileUploadAlternate}
                                            />
                                            <label
                                                htmlFor="logoUploadAlternate"
                                                width="100"
                                                height="88px"
                                                className='object-fit-cover'
                                                style={{ cursor: 'pointer', color: '#fff', background: '#f1592a', padding: '9px 20px', borderRadius: '7px', margin: '0', fontWeight: 'normal' }}
                                            >
                                                <span>Uploade Logo </span>
                                            </label>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    }

                    <div className="RosterSheet_background" >
                        <span ref={ref}>
                            <div className="RosterSheet" ref={targetRef}>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <img src={logo} alt="TeamConnect" className='object-fit-cover' height="110px" />
                                </div>
                                <Row className="text-center mb-4">
                                    <Col md={3}>
                                        <div className='d-flex justify-content-center gap-1 align-items-center'>
                                            <div>
                                                <label htmlFor="logoUpload" style={{ cursor: 'pointer' }}>
                                                    <img
                                                        src={selectedAlternateLogo || sunLogo}
                                                        alt="Sun Logo"
                                                        width="100"
                                                        height="88px"
                                                        className='object-fit-cover'
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="text-center my-4">
                                            <h2><b>Certified Roster and Minimum Play Sheet</b></h2>

                                        </div>
                                    </Col>
                                    <Col md={3}>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <div>
                                                <label htmlFor="logoUploadAlternate" style={{ cursor: 'pointer' }}>
                                                    <img
                                                        src={selectedAlternateLogo || sunLogo}
                                                        alt="Sun Logo"
                                                        width="100"
                                                        height="88px"
                                                        className='object-fit-cover'
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                                <div className='Team-details '>
                                    <div className='team-form '>
                                        <Row className="mb-4">
                                            <Col md={12}>
                                                <div className='form-team'>
                                                    <Form>
                                                        <div className='border border-dark p-2 '>  <Form.Group as={Row} className="" controlId="formPlaintext">
                                                            <Form.Label column sm="2" className='text-dark'>
                                                                Team Name :
                                                            </Form.Label>
                                                            <Col sm="6">
                                                                {selectedTeam?.team_name}
                                                                <Form.Control type='text' />

                                                            </Col>
                                                        </Form.Group>

                                                        </div>

                                                        <div className='border border-dark p-2 '>
                                                            <Form.Group as={Row} className="" controlId="formPlaintext">
                                                                <Form.Label column sm="2" className='text-dark'>
                                                                    Coach Name :
                                                                </Form.Label>
                                                                <Col sm="6">
                                                                    <Form.Control type="text" placeholder="" />
                                                                </Col>
                                                            </Form.Group>
                                                        </div>
                                                        <div className='border border-dark p-2 ' >
                                                            <Form.Group as={Row} className="" controlId="formPlaintext">

                                                                <Form.Label column sm="4" className='text-dark' style={{ width: "400px" }}>
                                                                    Roster is only valid if signed & stamped
                                                                </Form.Label>

                                                                <Col sm="6">
                                                                    <Form.Control type='text' />
                                                                </Col>
                                                            </Form.Group>
                                                        </div>
                                                    </Form>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    {/* Table Section */}
                                    <div className=' tracked_table'>
                                        <div className='text-center mb-3'> <h4><b> Plays must be tracked for only non-starters, minimum 10 plays</b></h4></div>
                                        <Row>
                                            <Col>
                                                <Table bordered striped>
                                                    <thead>
                                                        <tr className='border-top border-dark'>
                                                            <th className="important-th" style={{ width: '10%' }} > Jersey #</th>
                                                            {showImages && <th style={{ width: "15%" }}>Profile Picture <span style={{ fontSize: '10px' }}>Click to upload</span></th>}
                                                            <th className="important-th" >Name</th>
                                                            <th className="important-th " style={{ width: '10%' }} >Weight</th>
                                                            <th className="important-th" style={{ width: '10%' }} >X/Z</th>
                                                            <th colSpan="10" className="important-th" >10 Plays Tracker</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(players.length > 0 ? players : Array(15).fill({})).map((item, i) => (
                                                            <tr key={i}>
                                                                <td className="important-th">
                                                                    <Form.Control
                                                                        type="number"
                                                                        value={jerseyNumbers[i] || ''}
                                                                        onChange={(e) => handleJerseyChange(i, e.target.value)}
                                                                        onBlur={() => handleBlur(i, item.player_id)}
                                                                    />
                                                                </td>
                                                                {showImages && (
                                                                    <td>
                                                                        <Form.Group>
                                                                            <Form.Control
                                                                                style={{ display: 'none' }}
                                                                                type="file"
                                                                                accept="image/*"
                                                                                onChange={(e) => handleImageUpload(e, item.id)}
                                                                                id={`file-input-${item.id}`}
                                                                            />
                                                                            <img
                                                                                src={playerImages[item.id] || item.profile_pic || generateImage(item.firstname)}
                                                                                alt="Player"
                                                                                style={{
                                                                                    width: "100px",
                                                                                    height: "100px",
                                                                                    objectFit: "cover",  // or try "none" or "fill"
                                                                                    cursor: 'pointer',
                                                                                }}
                                                                                onClick={() => document.getElementById(`file-input-${item.id}`).click()} // Click on image triggers file input
                                                                            />
                                                                        </Form.Group>
                                                                    </td>
                                                                )}
                                                                <td className="important-th">
                                                                    {item.firstname || ''}
                                                                    <Form.Group>
                                                                        <Form.Control type="text" />
                                                                    </Form.Group>
                                                                </td>
                                                                <td className="important-th">
                                                                    {item.weight || ''}
                                                                    <Form.Group>
                                                                        <Form.Control type="text" />
                                                                    </Form.Group>
                                                                </td>

                                                                <td className="important-th"></td>

                                                                {/* 10 Play Tracker */}
                                                                {[...Array(10)].map((_, index) => (
                                                                    <td className="important-th" key={index}>{index + 1}</td>
                                                                ))}
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </Col>
                                        </Row>
                                    </div>

                                    <div className='feedbackform team-form'>
                                        <div className="border border-dark ">
                                            <h2 className="text-center mb-4 mt-2"><b>Ref Score Card</b></h2>
                                            <div className='form-team'>
                                                <Form>
                                                    {/* White Hat Name */}
                                                    <div className='border border-dark p-2 '>
                                                        <Form.Group as={Row} className="" controlId="whiteHatName">
                                                            <Form.Label column sm="2" className="font-weight-bold">
                                                                White Hat Name:
                                                            </Form.Label>
                                                            <Col sm="9">
                                                                <Form.Control type="text" placeholder="" />
                                                            </Col>
                                                        </Form.Group>
                                                    </div>

                                                    {/* Ref Score */}
                                                    <div className='border border-dark p-2 '>
                                                        <Form.Group as={Row} className="">
                                                            <Form.Label column sm="2" className="font-weight-bold">
                                                                Ref Score (Circle One):
                                                            </Form.Label>
                                                            <Col sm="9">
                                                                <div className="d-flex justify-content-around">
                                                                    <div className='text-center'>
                                                                        <span> <b>1. Good</b></span>
                                                                    </div>
                                                                    <div className='text-center'>
                                                                        <span><b> 2. Good</b></span>

                                                                    </div>

                                                                    <div className='text-center'>
                                                                        <span className> <b>3. Needs Work</b></span>

                                                                    </div>

                                                                </div>
                                                            </Col>
                                                        </Form.Group>
                                                    </div>

                                                    {/* Comments */}
                                                    <div className='border border-dark p-2 '>
                                                        <Form.Group as={Row} className="" controlId="comments">
                                                            <Form.Label column sm="2" className="font-weight-bold">
                                                                Comments:
                                                            </Form.Label>
                                                            <Col sm="9">
                                                                <Form.Control as="textarea" rows={5} />
                                                            </Col>
                                                        </Form.Group>
                                                    </div>

                                                    {/* Footer Text */}
                                                    <div className="text-center mt-4  pt-3">
                                                        <small>
                                                            *This sheet must be maintained during the game, available at
                                                            half-time, and turned into the field rep at the end of the game.*
                                                        </small>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </span>

                    </div>
                </Col>

                <Col xxl={3} xl={4} lg={4} md={4} sm={4}>
                    <div className=" h-100 bg-white mt-4">
                        <div className="p-4  position-fixed ">
                            <div className='roster-sidescroll'>

                                <h3><b>Roster Notification</b></h3>
                                <p style={{ textAlign: 'justify' }}>The Roster Notification feature allows users to send roster updates via email using a customizable template. Users can include details like the subject, district and league presidents, and a message, ensuring efficient communication of important updates</p>

                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    enableReinitialize={true}
                                    onSubmit={handleFormSubmit}
                                >
                                    {({ handleChange, handleBlur, handleSubmit, values, errors, touched }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    className="py-2"
                                                    type="text"
                                                    placeholder="Subject"
                                                    name="subject"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.subject}
                                                    isInvalid={touched.subject && !!errors.subject}
                                                    disabled={blocked > 0}
                                                />
                                                {touched.subject && errors.subject && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.subject}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>

                                            <Row className="mb-3">
                                                <Col>
                                                    <Form.Select
                                                        className="py-2"
                                                        name="districtPresident"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.districtPresident}
                                                        isInvalid={touched.districtPresident && !!errors.districtPresident}
                                                        disabled={blocked > 0}
                                                    >
                                                        <option value="">District President</option>
                                                        {allMembers.filter(item => item.role_id === 15).map(item => (
                                                            <option key={item.id} value={item.id}>{item.firstname}</option>
                                                        ))}
                                                    </Form.Select>
                                                    {touched.districtPresident && errors.districtPresident && (
                                                        <div className="text-danger">{errors.districtPresident}</div>
                                                    )}
                                                </Col>
                                                <Col>
                                                    <Form.Select
                                                        className="py-2"
                                                        name="leaguePresident"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.leaguePresident}
                                                        isInvalid={touched.leaguePresident && !!errors.leaguePresident}
                                                        disabled={blocked > 0}
                                                    >
                                                        <option value="">League President</option>
                                                        {allMembers.filter(item => item.role_id === 14).map(item => (
                                                            <option key={item.id} value={item.id}>{item.firstname}</option>
                                                        ))}
                                                    </Form.Select>
                                                    {touched.leaguePresident && errors.leaguePresident && (
                                                        <div className="text-danger">{errors.leaguePresident}</div>
                                                    )}
                                                </Col>
                                            </Row>

                                            <Form.Group className="mb-3">
                                                <Form.Control
                                                    as="textarea"
                                                    rows={4}
                                                    placeholder="Message"
                                                    name="message"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.message}
                                                    isInvalid={touched.message && !!errors.message}
                                                    disabled={blocked > 0}
                                                />
                                                {touched.message && errors.message && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.message}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>

                                            <Button
                                                className="w-100"
                                                type="submit"
                                                disabled={loader || blocked > 0}
                                                style={{ background: "#F1582A", padding: '10px 10px' }}

                                            >
                                                {
                                                    loader ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Send Roster Report'
                                                }

                                            </Button>
                                        </Form>
                                    )}
                                </Formik>
                                {
                                    history.length > 0 && !(blocked > 0) ? (
                                        <>
                                            <h5 className="mt-2 fw-bold">History</h5>
                                            <div className='roster-table-history'>

                                                {history.map((item) => (
                                                    <React.Fragment key={item.id}>


                                                        <div className="mt-2 text-muted d-flex justify-content-between align-items-center">
                                                            <span style={{ width: "100px" }}>
                                                                {item.group_name}
                                                            </span>
                                                            <span style={{ width: "100px" }}>
                                                                {item.team_name}
                                                            </span>
                                                            <span style={{ width: "160px" }}>
                                                                {moment(item.created_at).format('DD/MM/YYYY, h:mm A')}
                                                            </span>
                                                            <a
                                                                className="text-primary"
                                                                onClick={() => window.open(window.location.href + `?block=${item.id}`, '_blank')}
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    fill="currentColor"
                                                                    className="bi bi-eye"
                                                                    viewBox="0 0 16 16"
                                                                >
                                                                    <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8M1.173 8a13 13 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5s3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5s-3.879-1.168-5.168-2.457A13 13 0 0 1 1.172 8z" />
                                                                    <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5M4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0" />
                                                                </svg>
                                                            </a>
                                                        </div>

                                                        <hr />
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </>
                                    ) : null
                                }



                            </div>
                        </div>
                    </div>






                </Col>

            </Row>
        </>

    );
};

export default RosterSheet;
