import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";

export default function CheckoutForm({setStep,price,setPaymentIntent}) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const secretFromUrl = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
   
    if (secretFromUrl) {
     
      stripe.retrievePaymentIntent(secretFromUrl).then(({ paymentIntent }) => {
        setPaymentIntent(paymentIntent)
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
    }
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!stripe || !elements) {
      return;
    }
  
    setIsLoading(true);
  
    const { error ,paymentIntent} = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url:`${window.location.origin}/league-billing/payment-Success`
      },
      redirect: "if_required"
    });
    setPaymentIntent(paymentIntent)
  
    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("Something went wrong with the payment.");
      }
      setIsLoading(false);
    } else {
      // Payment succeeded
      setMessage("Payment succeeded!");
      setStep(2)
      setIsLoading(false);// Set the step value to 2
    }

  };
  
  
  const paymentElementOptions = {
    layout: "tabs"
  };

  return (
   <> <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" options={paymentElementOptions} />
      <button disabled={isLoading || !stripe || !elements} id="submit" placeholder="21212">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : `Pay  ( $ ${price} ) now` }
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form> </>
  );
}
