import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Payment from "../../pages/Payment"
import { Link, useNavigate } from 'react-router-dom';
import { Apipost } from '../../helpers/apiCall';
import { usePDF } from 'react-to-pdf';
import { useSelector } from 'react-redux';
import { GameType } from './GameType';
import { Loader } from '../../assets/Loader/Loader';



export const Paymentsuccess = ({ price, value, discountAmount = {},setRefresh, prevClose, promoSuccess={}, link = false, ...props }) => {
    const [show, setShow] = useState(false);
    const { toPDF, targetRef } = usePDF({ filename: 'paymentRecipt.pdf' });
    const handleClose = () => { setShow(false); prevClose() };
    const handleShow = () => {if(price == 0){
        setStep(3)
        handlePayment()
    } setShow(true)}
    const [loader, setLoader] = useState(false)
    const [paymentIntent, setPaymentIntent] = useState({})
    const [step, setStep] = useState(1)
    const item = value || {}
    const navigate = useNavigate()
    const selector = useSelector(state => state)
    let totalCharge = ((item.teamCount || 0) * (selector.PAYMENTDETAIL?.charges?.team_charge || 0));
    if (discountAmount[item.season_id]?.discountAmount) {
        totalCharge -= discountAmount[item.season_id].discountAmount;
    }
    let taxCharges = (((Number(selector.PAYMENTDETAIL?.charges?.transaction_charges) || 0) + (Number(selector.PAYMENTDETAIL?.charges?.tax) || 0)) / 100) * totalCharge;
    let grandTotal = totalCharge + taxCharges;
    useEffect(() => {

        if (step !== 1 && paymentIntent.client_secret && item.teams && item.season_id && Number(price) !== 0) {
            Apipost("/payment/update-payment", { client_secret: paymentIntent.client_secret, paymentIntent: paymentIntent, teams: item.teams, seasonId: item.season_id }, "application/x-www-form-urlencoded", true)
                .then((success) => {
                    setPaymentIntent(prev => ({ ...prev, cardDetails: success.data.data }))
                        setRefresh(prev =>!prev)
                })
                .catch((err) => {
                    console.error("Error updating payment:", err);
                });
        }
    }, [step, paymentIntent, item.teams, item.season_id])

   const handlePayment = ()=>{
    if(Number(price) === 0){
        setLoader(true)
        Apipost("/payment/add-payment", {teams: item.teams, seasonId: item.season_id , desc:item}, "application/x-www-form-urlencoded", true)
            .then((success) => {
                setPaymentIntent(prev => ({ ...prev, cardDetails: success.data.data }))
                setLoader(false)
            })
            .catch((err) => {
                console.error("Error updating payment:", err);
                setLoader(false)
            }); 
    }
   }

    return (
        <>
            <div className="Paymentsuccess">
                {link === true ? (
                    <Link onClick={price > 0 ? handleShow : () => { }}>$ {price}</Link>
                ) : (
                    <Button variant="primary" onClick={handleShow} className="me-2 pay_button btn" >
                        {<h6> Pay: $ {price ||0}</h6>}
                    </Button>
                )}
                <Offcanvas show={show} onHide={handleClose} {...props} className="sidenav_offcanvas ">
                {step === 1 ? (
    <div className="create_game px-3 mt-5">
        <div className="create-match-wrap season-popup mt-5">
            <div className='payment-enroll-details mt-5 '>
                <div className='payment-details-view'>
                    <div className='payment-enroll-details-legue'>
                    <GameType game_type_id ={item.game_type_id} />
                        <div className="date ms-3 mt-1 date-card pb-2">
                            <p><span><img  src={selector.SPORTSLIST.filter(items=>items.id == item.sport_id )[0]?.icon} width={30} /></span> {item.game_title} </p>
                            <p><strong>  </strong>{item.season_name}</p>
                        </div>
                    </div>
                </div>

                <div className='payment-method-list'>
                    <div className='top-payment'>
                        <div>
                            <p>{item.teamCount || 0} Teams x $ {selector.PAYMENTDETAIL.charges.team_charge} /Team</p>
                        </div>
                        <div>
                            <Link to="#"> <span className='taem-btn'> </span> $ {((item.teamCount || 0) * selector.PAYMENTDETAIL.charges.team_charge).toFixed(2)}</Link>
                        </div>
                    </div>
                    {discountAmount[item.season_id] && (
                        <div className='top-payment'>
                            <div>
                                {discountAmount[item.season_id].coupon_description}
                            </div>
                            <div>
                                $ - {(discountAmount[item.season_id].discountAmount).toFixed(2)}
                            </div>
                        </div>
                    )}
                    <div className='top-payment'>
                        <div>
                            <p>Additional  ({selector?.PAYMENTDETAIL.charges.transaction_charges || 0}%  Tax +{selector?.PAYMENTDETAIL?.charges.tax}%  Transaction Charges ) </p>
                        </div>
                        <div>
                            <Link to="#"> <span className='taem-btn'></span> $ {(taxCharges).toFixed(2) || 0} </Link>
                        </div>
                    </div>
                    {promoSuccess[item?.season_id] && <div className="text text-success">{promoSuccess[item.season_id]}</div>}
                    <hr></hr>
                    <div className='bottom-payment'>
                        <div className="orange-text">
                        </div>
                        <div className="pay_button " >
                            <div className="orange-text">
                                <p> Total: $ {(grandTotal)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Payment price={price} value={value} setStep={setStep} setPaymentIntent={setPaymentIntent} />
        </div>
    </div>
) : (
    <> {step === 2 ? <>  <div className="create_game px-3">
        <div className="create-match-wrap season-popup">
            <Offcanvas.Body>
                <div className='payment-divison-successful' ref={step == 2? targetRef:null}>
                    <div className='Sucessful'>
                       <SuccessIcon/>
                        <h3 className='payment-color'> Payment Sucessful !</h3>
                    </div>
                    <h5>Thank you ! You Payment has been recevied</h5>
                    <div className='transaction'>
                        <div className='d-flex'>  <span>Invoice ID :</span> <p className='ms-2'>{paymentIntent?.cardDetails?.orderId}</p></div>
                        <div className='d-flex'>  <span>Stripe Ref ID :</span> <p className='ms-2'>{paymentIntent?.id}</p></div>
                        <div className='d-flex'>  <span> Payment Method : </span> <p className='ms-2'>EFT(Check) XXXXXXXX {paymentIntent?.cardDetails?.last4}</p></div>
                        {item?.discountAmount && <div className='d-flex'>  <span> Coupon Applied : </span> <p className='ms-2'>{item.discountAmount?.coupon_code} </p></div>}
                        {item?.discountAmount && <div className='d-flex'>  <span> Discount Amount : </span> <p className='ms-2'>$ {item.discountAmount.discountAmount} </p></div>}
                    </div>
                    <h6>  Total  Amount Paid</h6>
                    <h3 className='total-amount'>${paymentIntent.amount / 100}.00</h3>
                    <p className='receipt-details'>A Receipt with details for this transaction has been sent via emails for your records. Click below to download and view details</p>
                    <div className='payment-btns d-flex'>
                        <Button variant="primary-download-btn" onClick={() => toPDF()}>Download Receipt</Button>{' '}
                        <Button href="#" variant="secondary" className=" ms-2" onClick={() => navigate(`/organizer/ALL/payment-detail/${paymentIntent?.cardDetails?.orderId}`)}>
                            View Details
                        </Button>
                    </div>
                </div>
            </Offcanvas.Body>
        </div>
    </div></> :<>
    <div className="create_game px-3">
    <div className="create-match-wrap season-popup">
        <Offcanvas.Body>
        {loader? <Loader/>: <div className='payment-divison-successful' ref={step == 3? targetRef:null}>
                <div className='Sucessful'>
                 <SuccessIcon/>
                    <h3 className='payment-color'> Payment Successful !</h3>
                </div>
                <h5>Thank you ! You Payment has been recevied</h5>
                    <div className='transaction'>
                        <div className='d-flex'>  <span>Invoice ID :</span> <p className='ms-2'>{paymentIntent?.cardDetails?.orderId}</p></div>
                        <div className='d-flex'>  <span>Stripe Ref ID :</span> <p className='ms-2'>{item.discountAmount?.coupon_code}</p></div>
                        <div className='d-flex'>  <span> Payment Method : </span> <p className='ms-2'>EFT(Check) XXXXXXXX {paymentIntent?.cardDetails?.last4}</p></div>
                        {item?.discountAmount && <div className='d-flex'>  <span> Coupon Applied : </span> <p className='ms-2'>{item.discountAmount?.coupon_code} </p></div>}
                        {item?.discountAmount && <div className='d-flex'>  <span> Discount Amount : </span> <p className='ms-2'>$ {item.discountAmount.discountAmount} </p></div>}
                    </div>
                <h6>Total Amount Paid</h6>
                <h3 className='total-amount'>$ 0.00</h3>
                <p className='receipt-details'>A confirmation email has been sent for your records. Click below to view details</p>
                <div className='payment-btns d-flex'>
                    <Button href="#" variant="secondary" className="ms-2" onClick={() => navigate(`/organizer/ALL/payment-detail/${paymentIntent?.cardDetails?.orderId}`)}>
                        View Details
                    </Button>
                </div>
            </div> }
            
        </Offcanvas.Body>
    </div>
</div>
    </> } </>
  
)}
                </Offcanvas>
            </div>
        </>
    )
}

  const SuccessIcon = ()=>{
    return                     <span>
    <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M27.6051 0C29.2441 0 30.8849 0 32.5239 0C33.4871 0.139959 34.4594 0.224671 35.4097 0.425402C47.2878 2.93546 55.0905 10.0016 58.8933 21.5058C59.5379 23.4542 59.816 25.4855 60.0462 27.5204V32.4392C59.9743 33.0377 59.9173 33.6399 59.827 34.2347C58.8215 40.8312 56.0334 46.5659 51.2692 51.2361C44.6562 57.7184 36.6748 60.6097 27.4191 59.8933C23.1909 59.5673 19.2333 58.3298 15.5373 56.2765C4.47686 50.1367 -1.97599 37.6195 0.543277 24.6567C2.85997 12.7344 10.0808 4.9575 21.5905 1.15282C23.5389 0.508273 25.572 0.232037 27.607 0H27.6051ZM47.6045 20.0547C47.6101 18.3144 46.3228 17.0327 44.6838 17.0106C43.6801 16.9977 42.9546 17.4986 42.2861 18.1689C36.8387 23.6236 31.3803 29.0691 25.944 34.5349C25.526 34.9548 25.2737 34.9566 24.8556 34.557C23.6991 33.4521 22.504 32.3858 21.3235 31.3048C19.9847 30.0802 18.6625 28.8353 17.2979 27.6382C15.5962 26.1447 13.0641 26.9127 12.5908 29.0599C12.3219 30.2772 12.7584 31.2698 13.67 32.0967C16.8946 35.0211 20.0989 37.9658 23.329 40.8828C24.9164 42.3155 26.3731 42.2529 27.9034 40.741C29.8334 38.835 31.7615 36.9253 33.6878 35.0156C37.9142 30.8242 42.1388 26.6309 46.367 22.4395C47.0631 21.7508 47.6543 21.0141 47.6027 20.0528L47.6045 20.0547Z" fill="#27AE60" />
    </svg>
</span>
  }