import { NavLink, useParams } from "react-router-dom";
import DynamicTable from "../../DynamicTable";
import MultiSelectDatePicker from "../../../components/DatePicker";
import Multiselect from "multiselect-react-dropdown";
import { useEffect, useRef, useState } from "react";
import { ApiGetAuth, Apipost } from "../../../helpers/apiCall";
import { useSelector } from "react-redux";
import printer from '../../../assets/images/printer.png';
import Loader from "../../Mainloader.js/Loader";
import NoData from "../../../components/NoData";


const multiselectCss = {
  multiselectContainer: {
    border: '1px solid #ccc',
    borderRadius: '8px',
    backgroundColor: 'white',
    padding: '7px',
    width: '200px',
    cursor: 'pointer',

  },

  optionListContainerdisplayBlock: {
    zIndex: 999,  // No quotes around the value
  },

  searchBox: {
    //  backgroundColor:'white',
    border: 'none',
    fontSize: '14px',
    padding: '5px',
    outline: 'none',
  },
  inputField: {
    border: 'none',
    // backgroundColor:'white',
    margin: '0',
    padding: '0',
    fontSize: '14px',
    width: '100%',
    boxShadow: 'none',
    cursor: 'pointer'
  },
  optionContainer: {
    border: '1px solid #ccc',
    // borderRadius: '4px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
  },
  option: {
    padding: '10px',
    fontSize: '14px',
    cursor: 'pointer',
  },
  selectedList: {
    display: 'none',
  },
  chips: {
    display: 'none',
  },
  groupHeading: {
    fontWeight: 'bold',
  },
  dropdownIndicator: {
    padding: '0 10px',
  },
  placeholder: {
    color: '#999',
  }
}


const DropdownIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 10l5 5 5-5H7z"
      fill="currentColor"
    />
  </svg>
);



function Reports() {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [generatedData, setGeneratedData] = useState(null);
  const [values, setValues] = useState({});
  const { sportId } = useParams();
  const [filter, setFilter] = useState({ events: '' });
  const [headers, setHeaders] = useState(['Date', 'Events', 'Season', 'Location', 'Time', 'Division']);
  const tableRef = useRef();

  const handleExport = () => {
    if (tableRef.current) {
      tableRef.current.exportToCSV();
    }
  };

  const optionsList = [
    { label: 'Team1', value: 'Team1' },
    { label: 'Team2', value: 'Team2' },
    { label: 'score_one', value: 'score_one' },
    { label: 'score_two', value: 'score_two' },
    { label: 'Comments', value: 'Comments' },
  ];

  const handleGetReportsList = (eventId = '') => {
    ApiGetAuth(`games/get-Reports-List?sports=${sportId}&&event=${eventId}`).then((data) => {
      if (data?.data) {
        setValues(data?.data);
      }
    });
  }

  useEffect(() => {
    handleGenerate()
  }, [])

  useEffect(() => {
    handleGetReportsList()
  }, [sportId]);

  const groupBy = (data, key) => {
    if (!key) return data
    return data.reduce((result, currentValue) => {
      const keyValue = currentValue[key];
      if (!result[keyValue]) {
        result[keyValue] = [];
      }
      result[keyValue].push(currentValue);
      return result;
    }, {});
  };

  const handleGenerate = () => {
    Apipost('/games/generate-Report', { ...filter, selectedDates, sportId }, 'application/x-www-form-urlencoded', true).then(
      (response) => {
        setGeneratedData(response.data.data || []);
        console.log(response, 'response');
      },
      (err) => {
        console.error(err);
      }
    );
  };

  function convertToDates(dateArray, key) {
    return dateArray?.map(item => {
      const [year, month, day] = item[key]?.split('-')?.map(Number);
      return new Date(year, month - 1, day);
    });
  }

  const handleOptionChange = (selectedList) => {
    if (selectedList.length >= 1) {
      setSelectedOptions(selectedList);
      setHeaders(prev => [...new Set(['Date', 'Events', 'season', 'Location', 'Time', 'Division', ...selectedList?.map(option => option.label)])]);
    }
  };

  const sports = useSelector(state => state.SPORTSLIST)

  const SelectComponent = () => {
    const groupedEvents = values.events ? groupBy(values.events, 'game_id') : {};


    console.log(filter.events, "filter.events")
    return (
      <select
        value={filter.events}
        onChange={(e) => {
          setFilter((prev) => ({
            ...prev,
            events: e.target.value,
          }));
          handleGetReportsList(e.target.value)
        }}
        className=""
      >
        <option value="" className="mb-2">All Events</option>
        {Object.keys(groupedEvents).length > 0 &&
          Object.keys(groupedEvents).map((gameId) => (
            <optgroup key={gameId} label={groupedEvents[gameId][0].game_title} className="mt-2 mb-2">
              {groupedEvents[gameId].map((item) => (
                <option key={item.id} value={item.id} className="mt-2 mb-2">
                  {item.season_name}
                </option>
              ))}
            </optgroup>
          ))}
      </select>
    );
  };

  return (
    <>
      <nav aria-label="breadcrumb" className="breadcrumb_dashboard">
        <ol className="breadcrumb">
          <li className="breadcrumb-item" aria-current="page">
            <NavLink to="/organizer/dashboard">Dashboard</NavLink>
          </li>
          <li className="breadcrumb-item">
            <NavLink to={`/organizer/${sportId}/events/All-Games`} role="button" tabIndex="0">{sports?.filter(item => item.id == sportId)[0]?.sports_name}</NavLink>
          </li>
          <li className="breadcrumb-item active">
            <NavLink to="#" role="button" tabIndex="0">Reports</NavLink>
          </li>
        </ol>
      </nav>
      <div className="organizer_events">

        <div className="reports-table">


          <div className=" mt-3 mb-2 d-flex justify-content-between">
            <div className="d-flex justify-content-between gap-3">


              <div className="fix-1">
                <SelectComponent />
              </div>
              <div className="fix-1">
                <select
                  onChange={(e) => {
                    setFilter((prev) => ({
                      ...prev,
                      venue: e.target.value,
                    }));
                  }}
                  className=""
                >
                  <option value="">All Venues</option>
                  {values.venues?.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="fix-1">
                <MultiSelectDatePicker
                  validDates={convertToDates(values.eventsDate || [], 'matchDate')}
                  placeholder="Select Event Dates"
                  selectedDates={selectedDates}
                  setSelectedDates={setSelectedDates}
                  className="w-100"
                />
              </div>
              <div className="fix-1">
                <button className="btn btn-primary w-100" onClick={handleGenerate}>
                  Generate
                </button>
              </div>
            </div>


            <div className="d-flex justify-content-between gap-3">
              <div className="fix-1">
                <Multiselect
                  className="w-100"
                  placeholder="Additional Info"
                  options={optionsList}
                  displayValue="label"
                  showCheckbox={true}
                  selectedValues={selectedOptions}
                  onRemove={handleOptionChange}
                  onSelect={handleOptionChange}
                  style={multiselectCss}
                  customArrow={<DropdownIcon />}  /* Adding the custom SVG dropdown icon */
                />
              </div>
              <div className="fix-1">
                <select placeholder="Short By"
                  value={filter.shortby}
                  onChange={(e) => {
                    setFilter((prev) => ({
                      ...prev,
                      shortby: e.target.value,
                    }));
                  }}>
                  <option value="">Filter</option>
                  <option value="Date">Date</option>
                  <option value="Division">Division</option>
                  <option value="Location">Location</option>
                  <option value="Season">Season</option>
                </select>
              </div>
              <div className="fix-1">
                <span onClick={handleExport} className="w-100" title="Download ">
                  <img
                    style={{ cursor: 'pointer', marginLeft: '30px' }}
                    src={printer}
                    width={40}
                    alt="CSV Icon"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        < >

          {generatedData == null ? <Loader /> : <>
            {
              (generatedData || []).length > 0 ? (
                <div className="mt-5 reports-table-view">  <DynamicTable headers={headers} ref={tableRef} data={groupBy(generatedData, filter.shortby)} shortby={filter.shortby} /> </div>
              ) : <div className="reports"> <NoData
                message="No games available at the moment."
                helpText="Check back soon! Once new games are added, you'll be able to generate reports here." /> </div>
            }
          </>}
        </>
      </div>

    </>
  );
}

export default Reports;
