import React, {useState} from 'react';

import { Container } from 'react-bootstrap';
import { LeftNav } from '../components/leftNav'
import DashboardNewComponent from '../components/DashboardComponent/dashboardNewComponent';

import '../assets/css/dashboardStructure.css';
import '../assets/css/components.css';
import '../assets/css/main.css';
import '../assets/css/owl.carousel.min.css'
import '../assets/css/owl.theme.default.css'
import '../assets/css/owl.theme.default.min.css'
// import '../assets/css/style.css'




import withLoader from '../components/PageLoader';
import Signout from '../components/Signout';


const LayoutWithOutLoader = () => {
    const [collapsed, setCollapsed] = React.useState(false);
	const [show, setShow] = useState(false)
	return (
		<>
			<section className="dashboard_organizer">
			<Signout show ={show} setShow={setShow} />
				<Container fluid>
					<div className="outerDashboard">
						<div className="sidebar">
							<LeftNav  collapsed={collapsed} setCollapsed={setCollapsed}  setShow={setShow} />	
						</div>
						<DashboardNewComponent  collapsed={collapsed} setCollapsed={setCollapsed} />
					</div>
				</Container>
			</section>
		</>
	);
};


const Layout = withLoader(LayoutWithOutLoader)


export { Layout };
