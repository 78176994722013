import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import "./index.css";
import { Apipost } from "../../helpers/apiCall";


export default function Payment(props) {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISH_KEY);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    if(props.price > 0){
      Apipost(props.baseUrl, {totalAmount :props.price,playerData:props.value , email:props.email}, "application/x-www-form-urlencoded", true).then((success) => {
        setClientSecret(success.data.clientSecret)
      }, (err) => {
      })
    }
    
  }, [props]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="payement">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm  setStep={props.setStep}  price={props.price} setPaymentIntent={props.setPaymentIntent} />
        </Elements>
      )}
    </div>
  );
}