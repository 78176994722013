import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { Col, Row, Table } from "react-bootstrap";
import { ApiGetAuth, Apipost } from "../../../helpers/apiCall";
import Form from "react-bootstrap/Form";
import moment from "moment";
import NoData from "../../../components/NoData";
import Loader from "../../Mainloader.js/Loader";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  errorMessageNodify,
  successMessageNodify,
} from "../../../helpers/notify";

const PlayersPaymentHistory = () => {
  const { sportId } = useParams();
  const [paymentHistory, setPaymentHistory] = useState(null);
  const [allData, setAllData] = useState([]);
  const [amounts, setAmounts] = useState({ totalPaid: "", totalDue: "" });
  const [filters, setFilters] = useState({
    gameTitle: "",
    seasonName: "",
    groupName: "",
    teamName: "",
    paymentStatus: "Due",
    startDate: "",
    endDate: "",
    search: "",
  });
  const [show, setShow] = useState(false);
  const [loading, setLoader] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [useremail, setUseremail] = useState("");
  const getPaymentHistory = async () => {
    try {
      const success = await ApiGetAuth(
        `payment/get-players-payment?sports_id=${sportId}`
      );
      const data = success.data.result;
      const { totalPaid, totalDue } = success.data;
      setAmounts({ totalPaid, totalDue });
      setAllData(data);

      if (data.length > 0) {
        // Extract default filter values from the first row of the data
        const defaultValues = {
          game_title: data[0].game_title || "",
          season_name: data[0].season_name || "",
          group_name: data[0].group_name || "",
          team_name: data[0].team_name || "",
        };

        setFilters((prevFilters) => ({
          ...prevFilters,
          ...defaultValues,
        }));

        filterData(data, defaultValues); // Filter data immediately
      } else {
        setPaymentHistory([]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getPaymentHistory();
  }, [sportId]);

  useEffect(() => {
    filterData();
  }, [filters]);

  const filterData = () => {
    const {
      game_title,
      season_name,
      group_name,
      team_name,
      payment_status,
      startDate,
      endDate,
      search,
    } = filters;

    const filteredData = allData.filter((item) => {
      const matchGameTitle = game_title ? item.game_title === game_title : true;
      const matchSeasonName = season_name
        ? item.season_name === season_name
        : true;
      const matchGroupName = group_name ? item.group_name === group_name : true;
      const matchTeamName = team_name ? item.team_name === team_name : true;
      const paymentStatus = payment_status
        ? item.payment_status === payment_status
        : true;
      const dateMatch =
        startDate && endDate
          ? moment(item.date_of_payment).format("YYYY-MM-DD") >= startDate &&
            moment(item.date_of_payment).format("YYYY-MM-DD") <= endDate
          : true;

      return (
        matchGameTitle &&
        matchSeasonName &&
        matchGroupName &&
        matchTeamName &&
        paymentStatus &&
        dateMatch
      );
    });

    setPaymentHistory(filteredData);
  };

  const handleFilterChange = (key, value) => {
    console.log(value, "vndfjhgfgfdgdfg");
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };
  const handleReminder = (index) => {
    console.log(index, "indexxxxx");
    setUseremail(index);
    handleShow();
  };
  const Reminder = () => {
    setLoader(true);
    Apipost(
      "/payment/send-payment-reminder",
      { associatedEmail: useremail },
      "application/x-www-form-urlencoded",
      true
    ).then(
      (response) => {
        successMessageNodify(response.data.msg);
        handleClose();
        setLoader(false);
      },
      (err) => {
        errorMessageNodify(err.response.data.msg);
        handleClose();
        setLoader(false);

        return {};
      }
    );
  };

  return (
    <>
      {/* Breadcrumb */}
      <nav aria-label="breadcrumb" className="breadcrumb_dashboard">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <NavLink to="#">Home</NavLink>
          </li>
          <li className="breadcrumb-item">
            <NavLink to="/organizer/dashboard">Dashboard</NavLink>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Players Payments
          </li>
        </ol>
      </nav>

      <div className="dashboard_inner_white_background">
        <Row>
          <Col>
            <div className="payment_history_page">
              <div className="payment_list_heading">
                <div className="select-payments d-flex">
                  {["game_title", "season_name", "group_name", "team_name"].map(
                    (filterKey) => (
                      <div className="pending-payments-details" key={filterKey}>
                        <Form.Select
                          aria-label={`Select ${filterKey.replaceAll(
                            "_",
                            " "
                          )}`}
                          value={filters[filterKey]}
                          onChange={(e) =>
                            handleFilterChange(filterKey, e.target.value)
                          }
                        >
                          <option value="">{`Select ${filterKey.replace(
                            /([A-Z])/g,
                            " $1"
                          )}`}</option>
                          {[
                            ...new Set(
                              allData
                                .map((item) => item[filterKey])
                                .filter(Boolean)
                            ),
                          ].map((value) => (
                            <option key={value} value={value}>
                              {value}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                    )
                  )}

                  <div
                    className="seasonDashboardElements mx-1"
                    style={{ fontSize: "14px" }}
                  >
                    <div className="item">
                      <div className="item-content">
                        <div className="heading">
                          <div className="season-items_board">
                            <div className="tolal-details bg bg-danger">
                              <h6>Due</h6>
                            </div>
                            <div>
                              <h4 style={{ fontSize: "30px" }}>
                                ${amounts.totalDue}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="seasonDashboardElements ">
                    <div className="item">
                      <div className="item-content">
                        <div className="heading">
                          <div className="season-items_board">
                            <div className="tolal-details bg bg-success">
                              <h6>Paid</h6>
                            </div>
                            <div className="">
                              <h4 style={{ fontSize: "30px" }}>
                                ${amounts.totalPaid}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="pending-payments-details">
                    <Form.Select
                      aria-label="Select Payment Status"
                      value={filters.payment_status}
                      onChange={(e) =>
                        handleFilterChange("payment_status", e.target.value)
                      }
                    >
                      <option value="Due">Due</option>
                      <option value="Paid">Successful</option>
                      <option value="Failed">Failed</option>
                    </Form.Select>
                  </div>

                  <div className="all_games_date_range">
                    <div className="input-right-icon">
                      <input
                        className="form-control"
                        type="date"
                        value={filters.startDate}
                        onChange={(e) =>
                          handleFilterChange("startDate", e.target.value)
                        }
                      />
                      <span>To</span>
                      <input
                        className="form-control"
                        type="date"
                        value={filters.endDate}
                        onChange={(e) =>
                          handleFilterChange("endDate", e.target.value)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="payment_listing_background">
                {paymentHistory === null ? (
                  <Loader />
                ) : paymentHistory.length > 0 ? (
                  <Table striped bordered>
                    <thead>
                      <tr>
                        <th>Player</th>
                        <th>Player Email</th>
                        <th>Payout Email(Parent/Coach)</th>
                        <th>Date of Payment</th>
                        <th>Payment</th>
                        <th>Transaction Id</th>
                        <th className="text-center">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentHistory.map((item, index) => (
                        <tr key={index}>
                          <td>{item.player}</td>
                          <td>{item.player_email}</td>
                          <td>
                            {Array.isArray(JSON.parse(item.associate_email)) ? (
                              <div className="d-flex flex-column">
                                {JSON.parse(item.associate_email).map(
                                  (item) => (
                                    <span>{item}</span>
                                  )
                                )}
                              </div>
                            ) : (
                              <span> ---------- </span>
                            )}
                          </td>
                          <td>{item.date_of_payment || item.payment_status}</td>
                          <td ><span className="float-right">${item.player_charged_amount ||  item.current_price}</span></td>
                          <td>{item.stripe_payment_id}</td>
                          <td>{item.payment_status === "Due" ?
                               <button
                               className="btn btn-primary"
                               onClick={() => handleReminder(JSON.parse(item.associate_email))}
 
                             >
                               Send Reminder
                             </button>:<></>
                            }
                       
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <NoData
                    message="No Payment History!"
                    helpText="New games will populate here when available."
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={`delete-popup ${show ? "open" : ""}`}>
        <div className="delete-popup-content">
          <h4>Send Reminder</h4>
          <p className="text-center mx-auto">
            Are you sure you want to Send Reminder ?
          </p>

          <div className="d-flex justify-content-center align-items-center">
            <button className="delete-popup-btn delete" onClick={Reminder}>
              <div className="d-flex align-items-center justify-content-center">
                {/* <img className="register-ico del-whi" src={delwhite} /> */}
                {loading ? (
                  <div>
                    {" "}
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                  </div>
                ) : (
                  "Send"
                )}
              </div>
            </button>
            <button className="delete-popup-btn cancel" onClick={handleClose}>
              {/* <img className="register-ico " src={close} /> */}
              Close
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlayersPaymentHistory;
